<template>
  <component :is="componentType" :joboffer="joboffer"></component>
</template>

<script>
import companyField from './company-field.vue';
import costCenterField from './cost-center-field.vue';
import departmentField from './department-field.vue';
import extentField from './extent-field.vue';
import locationField from './location-field.vue';
import tempJobField from './temp-job-field.vue';
import workingTimeField from './working-time-field.vue';
import applicationPeriodEndField from './application-period-end-field.vue';
import contractTypeField from './contract-type-field.vue';
import employmentDateField from './employment-date-field.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      require: true
    },

    field: {
      type: String,
      required: true
    }
  },

  computed: {
    componentType() {
      switch (this.field) {
        case 'location':
          return 'locationField';
        case 'company-name':
          return 'companyField';
        case 'cost-center':
          return 'costCenterField';
        case 'department':
          return 'departmentField';
        case 'working-time':
          return 'workingTimeField';
        case 'extent':
          return 'extentField';
        case 'temp-job':
          return 'tempJobField';
        case 'application-period-end':
          return 'applicationPeriodEndField';
        case 'contract-type':
          return 'contractTypeField';
        case 'employment-date':
          return 'employmentDateField';
        default:
          return '';
      }
    }
  },

  components: {
    applicationPeriodEndField,
    companyField,
    costCenterField,
    contractTypeField,
    departmentField,
    employmentDateField,
    extentField,
    locationField,
    tempJobField,
    workingTimeField
  }
};
</script>
