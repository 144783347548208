export default {
  getContent(state) {
    return state.content;
  },

  getJoboffers(state) {
    if (!state.filters) {
      return state.joboffers;
    }

    let jobs = state.joboffers;
    for (const [field, keyFilter] of Object.entries(state.filters)) {
      if (keyFilter.length > 0) {
        if (field === 'keyword') {
          const keyword = keyFilter[0].value;
          jobs = jobs.filter((x) =>
            x.name.some(
              (n) =>
                n.translation.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
            )
          );
        } else {
          jobs = jobs.filter((x) =>
            keyFilter.some((kf) => kf.value === x[field])
          );
        }
      }
    }

    return jobs;
  },

  getCareerLevels(state) {
    const levels = [];
    for (const job of state.joboffers) {
      const matchingLevel = levels.find((x) => x.id === job.careerLevelId);
      if (matchingLevel) {
        matchingLevel.numJobs++;
      } else {
        levels.push({
          field: 'careerLevelId',
          id: job.careerLevelId,
          name: job.careerLevel,
          numJobs: 1
        });
      }
    }

    return levels;
  },

  getCategories(state) {
    const categories = [];
    for (const job of state.joboffers) {
      const matchingCategory = categories.find((x) => x.id === job.categoryId);
      if (matchingCategory) {
        matchingCategory.numJobs++;
      } else {
        categories.push({
          field: 'categoryId',
          id: job.categoryId,
          name: job.category,
          numJobs: 1
        });
      }
    }

    return categories;
  },

  getContractTypes(state) {
    const contractTypes = [];
    for (const job of state.joboffers) {
      const matchingContractType = contractTypes.find((x) => x.id === job.contractTypeId);
      if (matchingContractType) {
        matchingContractType.numJobs++;
      } else {
        contractTypes.push({
          field: 'contractTypeId',
          id: job.contractTypeId,
          name: job.contractType,
          numJobs: 1
        });
      }
    }

    return contractTypes;
  },

  getLocations(state) {
    const locations = [];
    for (const job of state.joboffers) {
      const matchingLocation = locations.find((x) => x.id === job.locationId);
      if (matchingLocation) {
        matchingLocation.numJobs++;
      } else {
        locations.push({
          field: 'locationId',
          id: job.locationId,
          name: job.location,
          numJobs: 1
        });
      }
    }

    return locations;
  },

  getDepartments(state) {
    const departments = [];
    for (const job of state.joboffers) {
      if (job.departmentId <= 0) {
        continue;
      }

      const matchingDepartment = departments.find(
        (x) => x.id === job.departmentId
      );

      if (matchingDepartment) {
        matchingDepartment.numJobs++;
      } else {
        departments.push({
          field: 'departmentId',
          id: job.departmentId,
          name: job.department,
          numJobs: 1
        });
      }
    }

    return departments;
  },

  getWorkingTimes(state) {
    const workingTimes = [];
    for (const job of state.joboffers) {
      if (job.workingTimeId < 0) {
        continue;
      }

      const matchingWorkingTime = workingTimes.find(
        (x) => x.id === job.workingTimeId
      );

      if (matchingWorkingTime) {
        matchingWorkingTime.numJobs++;
      } else {
        workingTimes.push({
          field: 'workingTimeId',
          id: job.workingTimeId,
          name: job.workingTime,
          numJobs: 1
        });
      }
    }

    return workingTimes;
  },

  getCostCenters(state) {
    const costCenters = [];
    for (const job of state.joboffers) {
      if (job.costCenterId < 0) {
        continue;
      }

      const matchingCostCenter = costCenters.find(
        (x) => x.id === job.costCenterId
      );

      if (matchingCostCenter) {
        matchingCostCenter.numJobs++;
      } else {
        costCenters.push({
          field: 'costCenterId',
          id: job.costCenterId,
          name: job.costCenter,
          numJobs: 1
        });
      }
    }

    return costCenters;
  },

  getCompanies(state) {
    const companies = [];
    for (const job of state.joboffers) {
      if (job.companyId <= 0) {
        continue;
      }

      const matchingCompany = companies.find((x) => x.id === job.companyId);

      if (matchingCompany) {
        matchingCompany.numJobs++;
      } else {
        companies.push({
          field: 'companyId',
          id: job.companyId,
          name: job.companyName,
          numJobs: 1
        });
      }
    }

    return companies;
  }
};
