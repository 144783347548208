<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="error"
        dot
        offset-x="12"
        offset-y="12"
        :value="hasActiveFilters"
      >
        <v-btn
          color="primary"
          class="d-none d-block-md"
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </v-btn>

        <v-btn
          color="primary"
          small
          fab
          class="d-block d-none-md"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <div class="d-flex flex-column mobile-filter">
      <v-card>
        <!-- <v-card-text class="pt-6 pb-0"> -->
        <v-sheet class="rounded-lg pa-4 mobile-filter__content">
          <div class="d-flex align-center">
            <h3>Filter</h3>
            <v-btn fab text class="ml-auto" @click="clearAndClose">
              <v-icon large color="primary">mdi-close-circle</v-icon>
            </v-btn>
          </div>

          <v-row class="mt-8">
            <v-col cols="12">
              <v-text-field
                v-model="keyword"
                outlined
                filled
                hide-details
                append-icon="mdi-magnify"
                :placeholder="$t('jobs.fulltextSearch')"
                class="fulltext-search__search-field rounded"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('company-name')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.company') }}</h4>
              <div
                v-for="company in sortedCompanies"
                :key="`company_filter_${company.id}`"
              >
                <v-checkbox
                  v-model="companyFilters"
                  :label="`${localize(company.name)} (${company.numJobs})`"
                  :value="company"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('locations')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.location') }}</h4>
              <div
                v-for="location in sortedLocations"
                :key="`location_filter_${location.id}`"
              >
                <v-checkbox
                  v-model="locationFilters"
                  :label="`${localize(location.name)} (${location.numJobs})`"
                  :value="location"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('category')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.category') }}</h4>
              <div
                v-for="category in sortedCategories"
                :key="`category_filter_${category.id}`"
              >
                <v-checkbox
                  v-model="categoryFilters"
                  :label="`${localize(category.name)} (${category.numJobs})`"
                  :value="category"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('departments')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.department') }}</h4>
              <div
                v-for="department in sortedDepartments"
                :key="`department_filter_${department.id}`"
              >
                <v-checkbox
                  v-model="departmentFilters"
                  :label="`${localize(department.name)} (${
                    department.numJobs
                  })`"
                  :value="department"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('contract-types')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.contractType') }}</h4>
              <div
                v-for="contractType in sortedContractTypes"
                :key="`contract_type_filter_${contractType.id}`"
              >
                <v-checkbox
                  v-model="contractTypeFilters"
                  :label="`${localize(contractType.name)} (${
                    contractType.numJobs
                  })`"
                  :value="contractType"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('career-levels')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.careerLevel') }}</h4>
              <div
                v-for="careerLevel in sortedCareerLevels"
                :key="`careerLevel_filter_${careerLevel.id}`"
              >
                <v-checkbox
                  v-model="careerLevelFilters"
                  :label="`${localize(careerLevel.name)} (${
                    careerLevel.numJobs
                  })`"
                  :value="careerLevel"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('working-times')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.workingTime') }}</h4>
              <div
                v-for="workingTime in sortedWorkingTimes"
                :key="`working_time_filter_${workingTime.id}`"
              >
                <v-checkbox
                  v-model="workingTimeFilters"
                  :label="`${localize(workingTime.name)} (${
                    workingTime.numJobs
                  })`"
                  :value="workingTime"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filters.includes('cost-centers')" class="mt-8">
            <v-col cols="12">
              <h4>{{ $t('jobs.costCenter') }}</h4>
              <div
                v-for="costCenter in sortedCostCenters"
                :key="`cost_center_filter_${costCenter.id}`"
              >
                <v-checkbox
                  v-model="costCenterFilters"
                  :label="`${localize(costCenter.name)} (${
                    costCenter.numJobs
                  })`"
                  :value="costCenter"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-sheet>

        <!-- </v-card-text> -->
      </v-card>
      <v-sheet class="mobile-filter__actions elevation-3">
        <v-btn large block color="primary" @click="applyFilters">
          {{ $t('jobs.applyFilter') }}
        </v-btn>
      </v-sheet>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      showDialog: false,
      locationFilters: [],
      departmentFilters: [],
      careerLevelFilters: [],
      workingTimeFilters: [],
      companyFilters: [],
      categoryFilters: [],
      contractTypeFilters: [],
      costCenterFilters: [],
      keyword: ''
    };
  },

  computed: {
    ...mapState('jobsModule', {
      activeFilters: 'filters'
    }),

    ...mapGetters('jobsModule', {
      locations: 'getLocations',
      departments: 'getDepartments',
      careerLevels: 'getCareerLevels',
      workingTimes: 'getWorkingTimes',
      companies: 'getCompanies',
      categories: 'getCategories',
      contractTypes: 'getContractTypes',
      costCenters: 'getCostCenters'
    }),

    ...mapState('companyModule', {
      filters: 'filters'
    }),

    hasActiveFilters() {
      return (
        this.activeFilters && Object.entries(this.activeFilters).length > 0
      );
    },

    sortedCareerLevels() {
      return [...this.careerLevels].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedCategories() {
      return [...this.categories].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedContractTypes() {
      return [...this.contractTypes].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedDepartments() {
      return [...this.departments].sort((a, b) => {
        return this.localize(a.name).localeCompare(this.localize(b.name));
      });
    },

    sortedLocations() {
      return [...this.locations].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedWorkingTimes() {
      return [...this.workingTimes].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedCostCenters() {
      return [...this.costCenters].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedCompanies() {
      return [...this.companies].sort((a, b) => a.name.localeCompare(b.name));
    }
  },

  watch: {
    showDialog() {
      if (this.showDialog) {
        this.setCurrentFilters();
      }
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      clearFilters: 'clearFilters',
      setFilter: 'setFilter'
    }),

    applyFilters() {
      this.$store.state.jobsModule.filters = {};

      if (this.keyword) {
        this.setFilter({
          field: 'keyword',
          value: this.keyword
        });
      }

      if (this.locationFilters.length > 0) {
        for (const location of this.locationFilters) {
          this.setFilter({
            field: 'locationId',
            value: location.id,
            name: location.name
          });
        }
      }

      if (this.departmentFilters.length > 0) {
        for (const department of this.departmentFilters) {
          this.setFilter({
            field: 'departmentId',
            value: department.id,
            name: department.name
          });
        }
      }

      if (this.careerLevelFilters.length > 0) {
        for (const careerLevel of this.careerLevelFilters) {
          this.setFilter({
            field: 'careerLevelId',
            value: careerLevel.id,
            name: careerLevel.name
          });
        }
      }

      if (this.contractTypeFilters.length > 0) {
        for (const contractType of this.contractTypeFilters) {
          this.setFilter({
            field: 'contractTypeId',
            value: contractType.id,
            name: contractType.name
          });
        }
      }

      if (this.categoryFilters.length > 0) {
        for (const category of this.categoryFilters) {
          this.setFilter({
            field: 'categoryId',
            value: category.id,
            name: category.name
          });
        }
      }

      if (this.workingTimeFilters.length > 0) {
        for (const workingTime of this.workingTimeFilters) {
          this.setFilter({
            field: 'workingTimeId',
            value: workingTime.id,
            name: workingTime.name
          });
        }
      }

      if (this.costCenterFilters.length > 0) {
        for (const costCenter of this.costCenterFilters) {
          this.setFilter({
            field: 'costCenterId',
            value: costCenter.id,
            name: costCenter.name
          });
        }
      }

      if (this.companyFilters.length > 0) {
        for (const company of this.companyFilters) {
          this.setFilter({
            field: 'companyId',
            value: company.id,
            name: company.name
          });
        }
      }

      this.showDialog = false;
    },

    clearAndClose() {
      this.clearFilters();
      this.showDialog = false;
    },

    setCurrentFilters() {
      if (!this.filters || !this.activeFilters) {
        return;
      }

      if (
        this.filters.includes('departments') &&
        this.activeFilters['departmentId']
      ) {
        const departmentIds = this.activeFilters['departmentId'].map(
          (x) => x.value
        );
        this.departmentFilters = this.departments.filter((x) =>
          departmentIds.includes(x.id)
        );
      }

      if (
        this.filters.includes('locations') &&
        this.activeFilters['locationId']
      ) {
        const locationIds = this.activeFilters['locationId'].map(
          (x) => x.value
        );
        this.locationFilters = this.locations.filter((x) =>
          locationIds.includes(x.id)
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog .v-card {
  background-color: #ccc;
}
</style>
